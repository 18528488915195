<!--
/**
 *@author hhy 
 *@date 2023-2-10
  财务管理 对账管理 占位费收支账单详情 
*/
-->
<template>
  <custom-list
    warm-tips=""
    :search-data="searchData"
    :search-params="searchParams"
    :show-header="true"
    table-title="占位费收支账单明细"
    :table-columns="tableColumns"
    :table-operate-buttons="tableOperateButtons"
    :request-method="reconciliationApi.placeholderBillingDetailsList"
    :show-top-buttons="true"
    :page-top-buttons="pageTopButtons"
    :header-buttons="headerButtons"
    @clear-form="clearForm" />
</template>

<script>
  import { commission, reconciliationApi } from '@/service/apis';
  import YkcDialog from '@/components/ykc-ui/dialog';
  import CustomList from '@/components/biz/CustomList.vue';
  import { offlineExport } from '@/utils';

  export default {
    name: 'MerchantStatementsDetail',
    components: { CustomList },
    data() {
      return {
        id: '',
        dateType: '',
        payType: '',
        summaryDate: '',
        operatorId: '',
        reconciliationApi,
        dictionary: {},
        polymerizationInfo: {},
        pageButtons: [
          {
            id: '2',
            text: '导出',
            type: 'plain',
            handleClick: () => {
              this.doExportFile();
            },
          },
        ],
        pageTopButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
        tableData: [],

        tableColumns: [
          { label: '账单时间', prop: 'summaryDate', minWidth: '100px' },
          { label: '占位单号', prop: 'occupancyOrderNo', minWidth: '150px' },
          { label: '电站名称', prop: 'stationName', minWidth: '100px' },
          { label: '商户名称', prop: 'operatorName', minWidth: '100px' },
          { label: '交易时间', prop: 'tradeTime', minWidth: '150px' },
          { label: '用户账号', prop: 'userAccount', minWidth: '150px' },
          { label: '归属客户', prop: 'orgName', minWidth: '150px' },
          { label: '交易金额', prop: 'orderAmt', minWidth: '100px' },
        ],

        tableOperateButtons: [
          // {
          //   id: '1',
          //   text: '编辑',
          //   handleClick: (btn, { row }) => {
          //     this.$router.push({
          //       path: '/financeManage/agentCommissionApplication/edit',
          //       query: { id: row.id },
          //     });
          //   },
          // },
          // {
          //   id: '2',
          //   text: '详情',
          //   handleClick: (btn, { row }) => {
          //     this.$router.push({
          //       path: '/financialManagement/subsidiaryAccount/subDriverAccountsDetail',
          //       query: { id: row.id },
          //     });
          //   },
          // },
        ],
        pageInfo: {
          total: 0,
          pageIndex: 1,
          pageSize: 10,
        },
        searchParams: {
          // userAccount: '',
          // userName: '',
          // phoneNumber: '',
          // userId: '',
          // belongTo: '',
        },
        showDrawer: false,
        isEdit: false,
      };
    },
    created() {
      const { operatorId, dateType, payType, summaryDate } = this.$route.query;
      this.operatorId = operatorId;
      this.dateType = dateType;
      this.payType = payType;
      this.summaryDate = summaryDate;
      this.searchParams.operatorId = operatorId;
      this.searchParams.dateType = dateType;
      this.searchParams.payType = payType;
      this.searchParams.summaryStartDate = summaryDate;
      this.searchParams.summaryEndDate = summaryDate;
    },
    activated() {},
    computed: {
      headerButtons() {
        return [
          {
            label: '导出',
            handle: (data, ctx) => {
              console.log('-> data', data);
              console.log('-> ctx', ctx);
              offlineExport(
                {
                  downloadType: 'parking_order_bill_detail',
                  jsonNode: {
                    // downloadKey: 'message',
                    ...ctx.searchParams,
                  },
                },
                ctx.pageInfo.total
              );
            },
          },
        ];
      },
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'occupancyOrderNo',
            label: '占位单号',
            placeholder: '请输入占位单号',
          },
          {
            comName: 'YkcInput',
            key: 'stationName',
            label: '电站名称',
            placeholder: '请输入电站名称',
          },
          {
            comName: 'YkcInput',
            key: 'userAccount',
            label: '用户账号',
            placeholder: '请输入用户账号',
          },
          {
            comName: 'YkcInput',
            key: 'orgName',
            label: '归属客户',
            placeholder: '请输入归属客户',
          },
        ];
      },
    },
    methods: {
      clearForm(data) {
        this.searchParams = {
          ...data,
          operatorId: this.operatorId,
          dateType: this.dateType,
          payType: this.payType,
          summaryStartDate: this.summaryDate,
          summaryEndDate: this.summaryDate,
        };
      },
      handleChange(event, data) {
        console.log('event:', event);
        console.log('data:', data);
      },
      changeStateSearch() {
        this.pageInfo.pageIndex = 1;
        this.searchTableList();
      },
      /** 根据条件查询数据 */
      searchTableList() {
        Promise.all([
          commission.list({
            ...this.pageInfo,
            body: this.searchParams,
          }),
          commission.polymerization(this.searchParams),
        ]).then(([res1, res2]) => {
          this.tableData = res1.body;
          this.pageInfo.total = res1.totalCount || 0;
          this.polymerizationInfo = res2.body;
        });
      },
      /** 导出文件 */
      doExportFile() {
        commission.export('代理人佣金列表', this.searchParams);
      },
      onAddDrawerClose(done) {
        done();
      },
      /** 点击抽屉取消按钮 callback */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            drawerDone();
            if (this.isEdit) {
              this.isEdit = false;
            }
          },
        });
      },
      /**
       * @desc 点击页面左上角按钮 callback
       * */
      handlePageButtonClick(button, event) {
        if (typeof button.handleClick === 'function') {
          button.handleClick(button, event);
        }
      },
      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(form) {
        this.pageInfo.pageIndex = 1;
        Object.assign(this.searchParams, form);
        // if (form.createTime.length > 0) {
        //   [this.searchParams.createBeginTime, this.searchParams.createEndTime] = form.createTime;
        // }
        this.searchTableList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
        this.searchTableList();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
        this.searchTableList();
      },
    },
  };
</script>
<style lang="scss" scoped>
  .drawer-button-wrap {
    padding: 0 10px;
    button {
      margin-right: 5px;
    }
  }
  .link {
    color: blue;
    cursor: pointer;
  }
  .el-radio-group .item {
    border: 0;
  }
</style>
